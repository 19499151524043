.About {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
  }

  .subtitle {
    margin-top: 50px;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
  }
  .description {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 38px;
    text-indent: 30px;
  }
  .list {
    list-style: circle;
    margin-top: 30px;
    font-size: 1.5rem;
    font-weight: 500;
  }
  .list-item {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }
}
