.CircleNumber {
  position: relative;
  background-color: #000000;
  margin-right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  flex-shrink: 0;

  &::after {
    content: "";
    background-color: #000000;
    width: 25px;
    height: 25px;
    transform: rotate(45deg);
    position: absolute;
    bottom: 12.5px;
    left: 30px;
  }

  .num {
    background-color: #B9BEB9;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    z-index: 1;
  }
}
