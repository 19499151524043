.Instruction {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
  }
  .list {
    margin-top: 30px;
    font-size: 1.8rem;
    font-weight: 500;
  }
  .list-item {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }

  @media (max-width: 500px) {
    .title {
      font-size: 2rem;
      font-weight: 500;
    }
    .list {
      font-size: 1.2rem;
      font-weight: 400;
      margin-top: 10px;
    }
  }
}
