.Header {
  border-bottom: 2px solid #000000;
  color: inherit;

  .content {
    display: flex;
  }

  .logo-wrapper {
    padding: 10px 0;
    display: flex;
  }
  .logo {
    width: 70px;
    height: auto;
  }
  .logo-text-wrapper {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .logo-title {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: -0.011em;
  }
  .logo-subtitle {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.01em;
  }

  .nav {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  .nav-list {
    z-index: 10;
    display: none;
    position: absolute;
    min-width: fit-content;
    top: 95%;
    right: 0;
    padding: 20px;
    border: 2px solid #000000;
    background-color: #B9BEB9;
    list-style: none;

    &--is-active {
      display: block;
    }
  }
  .nav-list-item {
    border-bottom: 1px solid #000000;

    &:hover {
      color: #626262;
      border-bottom-color: #626262;
    }
  }
  .link {
    display: flex;
    text-decoration: none;
    color: inherit;
    font-size: 1.2rem;
    font-weight: 500;
    flex: 1;
    padding: 20px;
    white-space: nowrap;
  }
  /* ----menu btn---- */
  .menu-toggle {
    margin-left: auto;
    display: flex;
    background: transparent;
    cursor: pointer;
    width: 30px;
    height: 30px;
    flex-direction: column;
    justify-content: space-between;

    &--active {
      .bar:first-child {
        transform: translateY(14px) rotate(45deg);
      }
      .bar:nth-child(2) {
        scale: 0;
      }
      .bar:last-child {
        transform: translateY(-14px) rotate(-45deg);
      }
    }
  }

  .bar {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 5%;
    background-color: #000000;
    transition: all .5s ease-in-out; 
  }
  /* ----menu btn---- */


  @media (max-width: 550px) {
    .nav-list {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .link {
      white-space: normal;
    }

    .logo-title {
      font-size: 1.2rem;
      font-weight: 600;
    }
    .logo-subtitle {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  @media (max-width: 450px) {
    .logo-subtitle,
    .logo-title {
      display: none;
    }
  }
}