.Layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #B9BEB9;

  .wrapper {
    flex: 1 0 auto;
    color: inherit;
    background-color: inherit;
  }

  main {
    padding: 20px 0;
    height: 100%;
  }
}