.Footer {
  padding: 20px 0;
  background-color: #000000;
  color: #B9BEB9;

  .content-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
  }

  .logo {
    width: 70px;
    height: auto;
    flex-shrink: 0;
  }

  .nav-list {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .nav-list-item {
    padding: 10px 20px;
  }
  .link {
    color: inherit;
    font-size: 1.3rem;
    font-weight: 500;
    flex: 1;
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      color: #979797;
    }
  }

  .copyright {
    padding-top: 20px;
    border-top: 1px solid #B9BEB9;
    text-align: center;
    color: #B9BEB9;
    font-size: 1.1rem;
    line-height: 24px;
  }


  @media (max-width: 600px) {
    .link {
      font-size: 1rem;
    }
  }

  @media (max-width: 450px) {
    .copyright {
      font-size: .8rem;
    }

    .nav-list {
      display: none;
    }
  }
}