.Auth {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }
  .subtitle {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    padding-top: 20px;
  }
  .qr-img {
    margin-top: 40px;
    width: 400px;
  }

  .img {
    position: absolute;
    right: 0;
    bottom: 20px;
    width: 150px;
  }

  @media (max-width: 900px) {
    .img {
      display: none;
    }
  }

  @media (max-width: 450px) {
    .qr-img {
      width: 90%;
    }
  }
}
