html,
body,
#root {
  padding: 0;
  margin: 0;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #000000;
  height: 100%;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1, h2, h3 {
  margin: 0;
}

.link {
  color: inherit;
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1400px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}