.PersonalData {
  .text-align-right {
    text-align: right;
  }

  .pad-bot-s {
    padding-bottom: 10px;
  }

  .title {
    margin-top: 30px;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }
  .subtitle {
    margin-top: 10px;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
  }

  li {
    display: block;
    padding-top: 4px;
  }

  ol {
    counter-reset: item;
    line-height: 28px;
    padding-top: 7px;
    padding-left: 20px;

    & > li:before {
      content: counters(item, ".") ". ";
      counter-increment: item;
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 50px; 
    margin-bottom: 50px;
  }

  @media (max-width: 500px) {
    ol {
      padding-left: 20px;
    }

    .subtitle {
      font-size: 1rem;
    }

    .btn-wrapper {
      font-size: .8rem;
    }
  }
}
