.Button {
  text-transform: uppercase;
  background-color: #000000;
  border: 2px solid #000000;
  color: #B9BEB9;
  padding: 15px 25px;
  border-radius: 20px;
  font-weight: 600;
  font-size: inherit;

  &:hover {
    cursor: pointer;
    background-color: #B9BEB9;
    color: #4e4e4e;
  }
}
